export default class GooglePlace {

    constructor() {
    }

    init() {
        const reviews = document.getElementById("reviews__wrapper");

        const mapOptions = {
            center: new google.maps.LatLng(0, 0),
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            streetViewControl: false,
        }

        const map = new google.maps.Map(
            document.getElementById('google_map'),
            mapOptions
        );

        const request = {
            placeId: "ChIJe737KIovBEgR77ATOlFTFgg",
            fields: [
                'name',
                'geometry',
                'rating',
                'review',
            ],
        };

        const infowindow = new google.maps.InfoWindow();
        const service = new google.maps.places.PlacesService(map);

        service.getDetails(request, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK
                && place)
            {
                map.panTo(new google.maps.LatLng(place.geometry.location))

                const marker = new google.maps.Marker({
                    map,
                    position: place.geometry.location,
                });

                const showChar = 300;
                const ellipsestext = "..."
                const moretext = "more";
                const lesstext = "less";

                place.reviews.forEach(review => {
                    let html = '<div class="review">';

                    let content = review.text;

                    if (content.length > showChar) {
                        let c = content.substr(0, showChar);
                        let h = content.substr(showChar - 1, content.length - showChar);

                        /*let morelink = document.createElement('a');
                        let linkText = document.createTextNode(moretext);
                        morelink.appendChild(linkText);
                        morelink.onclick = function(){
                            if(this.classList.contains('less')) {
                                this.classList.remove('less');
                                this.innerHtml = moretext;
                            } else {
                                this.classList.add('less');
                                this.innerHtml = lesstext;
                            }
                            this.parentNode.previousElementSibling.toggle();
                            this.previousElementSibling.toggle();
                        }*/

                        html +=
                            '<div class="content">' +
                                c +
                                '<span class="moreellipses">' +
                                    ellipsestext +
                                '</span>' +
                                '<span class="morecontent">' +
                                    '<span>' +
                                        h +
                                    '</span>' +
                                    /*morelink +*/
                                '</span>' +
                            '</div>';
                    } else {
                        html +=
                            '<div class="content">' +
                                content +
                            '</div>';
                    }
                    html +=
                        '<div class="author_name">' +
                            review.author_name +
                        '</div>' +
                        '<div class="time">' +
                            review.relative_time_description +
                        '</div>' +
                        '<div class="rating" ' +
                            'style="--rating: ' + review.rating + '" ' +
                            'aria-label="Rating of this product is ' + review.rating + ' out of 5" ' +
                        '>' +
                        '</div> ' +
                    '</div>';

                    reviews.innerHTML += html;
                });

                //for slide animation
                reviews.innerHTML += reviews.innerHTML;
            }
        });
    }
}