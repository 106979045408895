export default class Ctas {
    constructor() {
        const map = document.querySelector(".map_integration");

        window.addEventListener('scroll', () => {
            var windowHeight = window.innerHeight;
            var elementTop = map.getBoundingClientRect().top;
            var elementVisible = 100;

            if(elementTop < windowHeight - elementVisible) {
                map.classList.add("active");
            } else {
                map.classList.remove("active");
            }
        });
    }
}