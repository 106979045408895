export default class Navbar {
    constructor() {
        const primaryNav = document.querySelector(".primary-navigation");
        const navToggle = document.querySelector(".mobile-nav-toggle");
        const header = document.querySelector(".primary-header");

        navToggle.addEventListener("click", () => {
            const visibility = primaryNav.getAttribute("data-visible");
            if(visibility === "false") {
                primaryNav.setAttribute("data-visible", "true");
                navToggle.setAttribute("aria-expanded", "true");
            } else if (visibility === "true") {
                primaryNav.setAttribute("data-visible", "false");
                navToggle.setAttribute("aria-expanded", "false");
            }
        });

        document.onscroll= () => {
            if(document.body.scrollTop > header.offsetHeight) {
                header.classList.add("scrolled");
            } else {
                header.classList.remove("scrolled");
            }

        };
    }

}
