// here you put all the js you want.
import Navbar from "./js/navbar";
import Slider from "./js/slider";
import GooglePlace from "./js/googlePlace";
import Keys from "./js/keys";
import Ctas from "./js/ctas";

const nav = new Navbar();

const keys = new Keys();

const ctas = new Ctas();

const place = new GooglePlace();

(() => {
    window.initialize = place.init;
})();
