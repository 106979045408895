export default class Keys {
    constructor() {
        const keysImages = document.querySelector(".keys__images");

        window.addEventListener('scroll', () => {
                var windowHeight = window.innerHeight;
                var elementTop = keysImages.getBoundingClientRect().top;
                var elementVisible = 50;

                if(elementTop < windowHeight - elementVisible) {
                    keysImages.classList.add("active");
                } else {
                    keysImages.classList.remove("active");
                }
        });
    }
}